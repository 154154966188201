/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: false, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Arycer",
  title: "¡Hola! Soy Arycer",
  subTitle: emoji(
    "Soy desarrollador de software con experiencia en modding de videojuegos y el desarrollo de aplicaciones. Me encanta aprender cosas nuevas y crear cosas que puedan ayudar a las personas."
  ),
  resumeLink:
    "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Arycer",
  twitter: "https://twitter.com/ArycerX",
  instagram: "https://www.instagram.com/arycerx/",
  gmail: "contacto@arycer.me",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Mis habilidades",
  subTitle: "Desarrollo de software y modding de videojuegos",
  skills: [
    emoji("⚡ Modding de Minecraft con Forge y Fabric"),
    emoji("⚡ Desarrollo de bots de Discord con Discord.js"),
    emoji("⚡ Uso de Git y GitHub para el control de versiones")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git-alt"
    },
    {
      skillName: "github",
      fontAwesomeClassname: "fab fa-github"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "IES Huarte de San Juan",
      logo: require("./assets/images/huartelogo.png"),
      subHeader: "Bachillerato de Ciencias y Tecnología",
      duration: "Septiembre 2021 - Junio 2023",
      desc: "Estudiando Bachillerato de Ciencias y Tecnología en el IES Huarte de San Juan",
      descBullets: []
    },
    {
      schoolName: "IES Virgen del Carmen",
      logo: require("./assets/images/virgendelcarmenlogo.png"),
      subHeader: "Grado Superior de Desarrollo de Aplicaciones Multiplataforma",
      duration: "Septiembre 2023 - Presente",
      desc: "Estudiando Desarrollo de Aplicaciones Multiplataforma en el IES Virgen del Carmen",
      descBullets: []
    }
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Desarrollador de software",
      company: "Eufonia Studio",
      companylogo: require("./assets/images/eufonialogo.png"),
      date: "Mayo 2023 – Presente",
      desc: "Desarrollo de series y eventos en Minecraft con Fabric",
      descBullets: [
        "Series y eventos de Minecraft para creadores de contenido",
        "Twitch Rivals de Minecraft",
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Proyectos",
  subtitle: "Algunos de los proyectos en los que he trabajado",
  projects: [
    {
      image: require("./assets/images/eddtlogo.png"),
      projectName: "El Dios de Todo",
      projectDesc: "Serie de Minecraft para JuanSGuarnizo",
      footerLink: [
        {
          name: "Lista de reproducción",
          url: "https://www.youtube.com/playlist?list=PL3kCkbalPgdN0E2AjA5Qf5nDHwiTksY91"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/experimento110.png"),
      projectName: "Ultra Hardcore: Experimento 110",
      projectDesc: "Serie de Minecraft para SpreenDMC",
      footerLink: [
        {
          name: "Lista de reproducción",
          url: "https://www.youtube.com/playlist?list=PL_UYaz590HIqK3ZONaU06k0h2aRO7TrGF"
        }
      ]
    },
    {
      image: require("./assets/images/experimentohardcorelogo.png"),
      projectName: "Experimento Hardcore",
      projectDesc: "Serie de Minecraft para SpreenDMC",
      footerLink: [
        {
          name: "Twitter Oficial",
          url: "https://twitter.com/experimentohard"
        }
      ]
    },
    {
      image: require("./assets/images/huntandrun.png"),
      projectName: "Hunt and Run",
      projectDesc: "Twitch Rivals en Minecraft ft. IlloJuan",
      footerLink: [
        {
          name: "Twitter de Eufonia",
          url: "https://twitter.com/EufoniaStudio"
        }
      ]
    },
    {
      image: require("./assets/images/skyblock.png"),
      projectName: "Patxingar's Skyblock",
      projectDesc: "Skyblock para IlloJuan, elBokeron y Guille",
      footerLink: [
        {
          name: "Twitter de Eufonia",
          url: "https://twitter.com/EufoniaStudio"
        }
      ]
    },
    {
      image: require("./assets/images/eufoniaclub.png"),
      projectName: "Eufonia Club: Battle Royale",
      projectDesc: "Servidor público de Battle Royale en Minecraft",
      footerLink: [
        {
          name: "Twitter de Eufonia",
          url: "https://twitter.com/EufoniaStudio"
        }
      ]
    },
    {
      image: require("./assets/images/eufoniasmp.png"),
      projectName: "Eufonia SMP",
      projectDesc: "Servidor público de supervivencia en Minecraft con mods personalizados",
      footerLink: [
        {
          name: "Patreon de Eufonia",
          url: "https://patreon.com/eufonia"
        }
      ]
    },
    {
      image: require("./assets/images/backrooms2.png"),
      projectName: "The Backrooms: vol 2",
      projectDesc: "Evento de Minecraft para Soarinng",
      footerLink: [
        {
          name: "Canal de Soarinng",
          url: "https://www.youtube.com/@soarinngchu"
        }
      ]
    },
    {
      image: require("./assets/images/buildbattle.png"),
      projectName: "Juaniquilacopa 2: Build Battle",
      projectDesc: "Evento de Minecraft para JuanSGuarnizo",
      footerLink: [
        {
          name: "Canal de JuanSGuarnizo",
          url: "https://www.youtube.com/@JuanSGuarnizo"
        }
      ]
    },
    {
      image: require("./assets/images/dedsafio3.png"),
      projectName: "Dedsafio Minecraft 3",
      projectDesc: "Serie de Minecraft para ElDed",
      footerLink: [
        {
          name: "Canal de ElDed",
          url: "https://www.youtube.com/@Mrdedreviil"
        }
      ]
    },
    {
      image: require("./assets/images/sc3.jpg"),
      projectName: "SquidCraft Games 3",
      projectDesc: "Twitch Rivals en Minecraft ft. Komanche, AuronPlay y Rubius",
      footerLink: [
        {
          name: "Twitter de SquidCraft",
          url: "https://x.com/squidcraftgames"
        }
      ]
    },
    {
      image: require("./assets/images/salvando_navidad.png"),
      projectName: "Salvando la Navidad",
      projectDesc: "Serie de Minecraft para WestCOL",
      footerLink: [
        {
          name: "Canal de WestCOL",
          url: "https://kick.com/westcol"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji(""),
  subtitle:
    "",

  achievementsCards: [
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "",
  subtitle: emoji(
    ""
  ),

  talks: [
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji(""),
  subtitle: "",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Información de contacto"),
  subtitle:
    "¡Hablemos de trabajo! Si crees que puedo ayudarte en tu proyecto, no dudes en contactarme.",
  number: "",
  email_address: "contacto@arycer.me"
};

// Twitter Section

const twitterDetails = {
  userName: "ArycerX", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
